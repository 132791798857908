<main>
<header>
    <div>
        <p><a href="/home">{{title}}</a></p>
        <p><a href="" (click)="logout()">{{user_name}}</a></p>
    </div>
</header>

<article>
<router-outlet></router-outlet>
</article>

<footer>
<nav>
  <a routerLink="/contact" id="contact">
      <mat-icon>contact_page</mat-icon> Contact
  </a>
  <a routerLink="/faq" id="faq">
      <mat-icon>quiz</mat-icon> FAQ
  </a>
  <a routerLink="/privacy" id="faq">
      <mat-icon>policy</mat-icon> Privacy
  </a>
</nav>
</footer>
</main>
