import { Component } from '@angular/core';
import { FordService } from '../services/ford.service';
import { Router } from '@angular/router';
import { DealerCurrent } from '../data/dealer';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent {
  constructor(
    private fordService: FordService,
    private _router: Router
  ) {}

  cancelSubscription(): void {
    var dealerCode = `${DealerCurrent.country3}${DealerCurrent.pa_code}`;
    this.fordService.deleteSubscription(dealerCode).subscribe(
      () => this._router.navigateByUrl('/status-enrolled')
    );
  }
}
