import { Component } from '@angular/core';
import { Subscription } from '../data/subscription';
import { FordService } from '../services/ford.service';
// import { DealerService } from '../services/dealer.service';
import { Dealer, DealerCurrent } from '../data/dealer';
import { Price } from '../data/price';
import { FuncsService } from '../services/funcs.service';
// import { Customer, CustomerCurrent } from '../data/customer';

@Component({
  selector: 'app-status-enrolled',
  templateUrl: './status-enrolled.component.html',
  styleUrls: ['./status-enrolled.component.css']
})
export class StatusEnrolledComponent {
  sub: Subscription = {
    createdAt: "",
    siteCode: "",
    maxAllowedSessions: 0,
    concurrentSessions: 0,
    expiresAt: "",
    status: 200
  };

  prices: Price = {
    per_tech: 0,
    monthly: 0
  };

  public loading = true;
  public price = 0;
  public monthly = 0;
  public enrolled = "";
  dealer: Dealer = DealerCurrent;
  // customer: Customer = CustomerCurrent;

  constructor(
    private fordService: FordService,
    // private dealerService: DealerService,
    private funcs: FuncsService
  ) {}

  async ngOnInit(): Promise<void> {
    // this.funcs.currentCustomer(CustomerCurrent);
    this.funcs.currentDealer(this.dealer);
    // if (!DealerCurrent || DealerCurrent.pa_code) {
    //   await this.dealerService.getDealer(CustomerCurrent.pa_code);
    //   this.funcs.setDealer(this.dealer);
    // }
    this.getSubscription();
    this.loading = true;
  }

  // async getDealer(): Promise<void> {
  //   this.fordService.getSubscription(DealerCurrent.pa_code).subscribe(sub => {
  //     this.sub = sub;
  //     this.prices = this.funcs.priceByTier(DealerCurrent.tech_count);

  //     this.loading = false;
  //     this.enrolled = new Date(this.sub.createdAt).toDateString();
  //   });
  // }

  getSubscription(): void {
    this.fordService.getSubscription(`${this.dealer.country3}${this.dealer.pa_code}`).subscribe(sub => {
      this.sub = sub;
      this.prices = this.funcs.priceByTier(this.dealer.tech_count, this.dealer.country3);

      this.loading = false;
      this.enrolled = new Date(this.sub.createdAt).toDateString();
    });
  }
}
