import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dealer, DEALERS, DealerCurrent } from '../data/dealer';
import { FuncsService } from '../services/funcs.service';
import { Router } from '@angular/router';
import { DealerService } from '../services/dealer.service';

@Component({
  selector: 'app-report-enrolled',
  templateUrl: './report-enrolled.component.html',
  styleUrls: ['./report-enrolled.component.css']
})

export class ReportEnrolledComponent {
  public loading = "Loading...";
  dealers = DEALERS;
  displayedDealers = this.dealers;
  search = "";
  enrolled = "E";
  queryEnrolled: string = "";

  constructor(
    private dealerService: DealerService,
    private router: Router,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.loading = "Loading...";

    this.queryEnrolled = this.route.snapshot.queryParamMap.get('enrolled') || '';
    if (["E", "U", "A"].indexOf(this.queryEnrolled) > -1) {
      this.enrolled = this.queryEnrolled;
    }
    this.dealerService.getDealers().subscribe(dealers => {
      this.dealers = dealers;
      this.loading = "";
      this.displayDealers();
    });
  }

  ngAfterViewInit(): void {
    document.getElementById("admin-dealer")?.focus();
  }

  changeRadio(eventEnroll: Event) {
    this.enrolled = (eventEnroll.target as HTMLInputElement).value;
    this.displayDealers();
  }

  displayDealers() {
    this.displayedDealers = this.filterDealers();
  }

  private filterDealers = () => {
    return this.dealers.filter(this.isDealer);
  };

  private isDealer = (d: Dealer) => {
    var ciSearch = this.search.toLowerCase();
    var ciName = d.name.toLowerCase();
    return (d.pa_code.indexOf(ciSearch) > -1 || ciName.indexOf(ciSearch) > -1) && this.isEnrolled(d);
  };

  private isEnrolled = (d: Dealer) => {
    if (this.enrolled === "E" && d.subscribed === "Y") {
      return true;
    }
    if (this.enrolled === "U" && d.subscribed === "N") {
      return true;
    }
    if (this.enrolled === "A") {
      return true;
    }
    return false;
  };

  fly(d: Dealer) {
    DealerCurrent.pa_code = d.pa_code;
    DealerCurrent.name = d.name;
    DealerCurrent.tech_count = d.tech_count;
    DealerCurrent.subscribed = d.subscribed;
    DealerCurrent.active = d.active;

    // persist data
    var dealerStore = {
      pa_code: DealerCurrent.pa_code,
      name: DealerCurrent.name,
      tech_count: DealerCurrent.tech_count,
      subscribed: DealerCurrent.subscribed,
      active: DealerCurrent.active
    };
    window.localStorage.setItem("dealer", JSON.stringify(dealerStore));
    this.router.navigateByUrl('/status-enrolled');
  };
}
