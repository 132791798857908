import { Tier } from './tier';

export const Tiers: Tier[] = [
    {min: 0, max: 0, price: 0},
    {min: 1, max: 9, price: 405},
    {min: 10, max: 24, price: 340},
    {min: 25, max: 49, price: 305},
    {min: 50, max: Infinity, price: 290}
];

export const TiersUsa: Tier[] = [
    {min: 0, max: 0, price: 0},
    {min: 1, max: 9, price: 405},
    {min: 10, max: 24, price: 340},
    {min: 25, max: 49, price: 305},
    {min: 50, max: Infinity, price: 290}
];

export const TiersCan: Tier[] = [
    {min: 0, max: 0, price: 0},
    {min: 1, max: 9, price: 535},
    {min: 10, max: 24, price: 450},
    {min: 25, max: 49, price: 400},
    {min: 50, max: Infinity, price: 380}
];
