<h1>Admin</h1>

<section>
    <p><a href="/admin-dealer">Log in as Dealer</a></p>
</section>

<section>
    <h2>Reports</h2>
    <p><a href="/report-enrolled">Enrolled Dealers</a></p>
    <p><a href="/report-enrolled?enrolled=U">Unenrolled Dealers</a></p>
    <p><a href="/report-monthly-revenue">Monthly Revenue</a></p>
    <p><a href="/report-tiers">Dealers in Tiers</a></p>
</section>

<section>
    <p><button (click)="logOut()">Log Out</button></p>
</section>