import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatIconModule} from '@angular/material/icon';
import { HomeComponent } from './home/home.component';
import { AdminDealerComponent } from './admin-dealer/admin-dealer.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { CancelComponent } from './cancel/cancel.component';
import { CancelConfirmComponent } from './cancel-confirm/cancel-confirm.component';
import { ContactComponent } from './contact/contact.component';
import { EnrollComponent } from './enroll/enroll.component';
import { EnrollConfirmComponent } from './enroll-confirm/enroll-confirm.component';
import { FaqComponent } from './faq/faq.component';
import { ReportsComponent } from './reports/reports.component';
import { StatusEnrolledComponent } from './status-enrolled/status-enrolled.component';
import { ReportTiersComponent } from './report-tiers/report-tiers.component';
import { ReportMonthlyRevenueComponent } from './report-monthly-revenue/report-monthly-revenue.component';
import { ReportEnrolledComponent } from './report-enrolled/report-enrolled.component';
import { AutoLoginComponent } from './auto-login/auto-login.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminDealerComponent,
    AdminHomeComponent,
    CancelComponent,
    CancelConfirmComponent,
    ContactComponent,
    EnrollComponent,
    EnrollConfirmComponent,
    FaqComponent,
    ReportsComponent,
    StatusEnrolledComponent,
    ReportTiersComponent,
    ReportMonthlyRevenueComponent,
    ReportEnrolledComponent,
    AutoLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
