import { Component } from '@angular/core';
import { FuncsService } from './services/funcs.service';
import { Customer, CustomerCurrent } from './data/customer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'IDS Enrollment';
  user_name = "";

  constructor(
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.funcs.currentCustomer(CustomerCurrent);
    if (CustomerCurrent) {
      this.user_name = CustomerCurrent.name;
    }
  }

  logout(): void {
    this.funcs.logOutCustomer();
  }
}