import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Subscription } from '../data/subscription';

@Injectable({
  providedIn: 'root',
})
export class FordService {
  private fordUrl = `${environment.apiUrl}/api/SubscriptionItems`;

  constructor(private http: HttpClient) { }

  getSubscription(id: string): Observable<Subscription> {
    const url = `${this.fordUrl}/${id}`;
    return this.http.get<Subscription>(url)
      .pipe(
        tap(_ => this.log('fetched subscription')),
        catchError(this.handleError<Subscription>('getSubscription'))
      );
  }

  cancelSubscription(id: string): Observable<Subscription> {
    const url = `${this.fordUrl}/${id}`;
    return this.http.delete<Subscription>(url)
      .pipe(
        tap(_ => this.log('cancelled subscription')),
        catchError(this.handleError<Subscription>('getSubscription'))
      );
  }

  addSubscription(sub: Subscription): Observable<Subscription> {
    const url = `${this.fordUrl}/${sub.siteCode}`;
    return this.http.post<Subscription>(url, sub)
      .pipe(
        tap(_ => this.log('cancelled subscription')),
        catchError(this.handleError<Subscription>('getSubscription'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  deleteSubscription(id: string): Observable<Subscription> {
    const url = `${this.fordUrl}/${id}`;

    return this.http.delete<Subscription>(url).pipe(
      tap(_ => this.log(`deleted subscription id=${id}`)),
      catchError(this.handleError<Subscription>('deleteHero'))
    );
  }

  private log(message: string) {
    console.log(message);
  }
}