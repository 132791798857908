<h1><a href="/admin-home">Admin</a> - Dealers</h1>

<section>
    <p><label>Dealer: 
        <input type="text" id="admin-dealer" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus">
    </label></p>
    <p>
        <label><input type="radio" name="enrolled" value="E" (click)="changeRadio($event)" checked="checked">Enrolled</label>
        <label><input type="radio" name="enrolled" value="U" (click)="changeRadio($event)">Not Enrolled</label>
        <label><input type="radio" name="enrolled" value="A" (click)="changeRadio($event)">All</label>
    </p>
</section>

<section>
    <p>{{loading}}</p>
    <table class="data">
        <thead><tr><td>PA Code</td><td>Name</td><td>Techs</td></tr></thead>
        <tbody>
            <tr *ngFor="let dealer of displayedDealers">
                <td>{{dealer.pa_code}}</td>
                <td>{{dealer.name}}</td>
                <td class="number">{{dealer.tech_count}}</td>
                <td><button (click)="fly(dealer)">Be {{dealer.pa_code}}</button></td>
            </tr>
        </tbody>
    </table>
</section>
