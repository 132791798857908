<h1>Dealer Status Page - <span *ngIf="sub.status == 404" class="name">Not</span> Enrolled</h1>

<ng-template [ngIf]="loading">
    <section>
        <p>Getting subscription information...</p>
    </section>
</ng-template>

<ng-template [ngIf]="!sub.status">
<section>
<h2>You are enrolled!</h2>
<ul>
    <li>Enrolled On: {{enrolled}}
    <li>PA Code: {{sub.siteCode}}
    <!-- <li>Max Allowed Sessions: {{sub.maxAllowedSessions}}
    <li>Concurrent Sessions: {{sub.concurrentSessions}} -->
    <li>Technicians: {{dealer.tech_count}}
    <li>Cost/technician: ${{prices.per_tech.toFixed(2)}}
    <li>Monthly charges: ${{prices.monthly.toFixed(2)}}
</ul>
<p><button [routerLink]="['/cancel']">Cancel Subscription</button>
</section>
</ng-template>

<ng-template [ngIf]="sub.status == 404">
<section>
    <p>You not are enrolled! You won't be able to use your thing.</p>
    <p><button [routerLink]="['/enroll']">Enroll Now</button>
</section>
</ng-template>
